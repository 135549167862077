<template>
  <el-table :data="users" style="width: 100%">
    <el-table-column prop="id" label="uid" width="70" />
    <el-table-column label="name" width="200">
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: scope.row.id }
          }"
        >
          {{ scope.row.name }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="chineseName" label="chinese_name" width="200" />
    <el-table-column prop="username" label="username" />
    <el-table-column prop="email" label="email" />
    <el-table-column prop="phone" label="phone" width="140" />
    <el-table-column prop="lineId" label="line" width="140" />
    <el-table-column prop="wechat" label="Wechat" width="140" />
    <el-table-column label="action" width="140" align="center">
      <template slot-scope="scope">
        <el-tooltip
          class="item"
          content="User Profile"
          placement="top"
          popper-class="tooltipColor"
        >
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: scope.row.id }
            }"
          >
            <i class="fas fa-eye action-icon" />
          </router-link>
        </el-tooltip>
        <el-tooltip
          class="item"
          :content="`Click to mark ${scope.row.isActive ? 'inactive' : 'active'}`"
          placement="top"
          popper-class="tooltipColor"
        >
          <span
            key="inactive"
            v-if="scope.row.isActive"
            @click="$emit('toggleUserActive', scope.row.id)"
          >
            <i class="far fa-stop-circle action-icon" />
          </span>
          <span
            key="active"
            v-else
            @click="$emit('toggleUserActive', scope.row.id)"
          >
            <i class="far fa-play-circle action-icon" />
          </span>
        </el-tooltip>
        <el-tooltip
          class="item"
          content="Delete user"
          placement="top"
          popper-class="tooltipColor"
        >
          <span
            @click="
              () => {
                $emit('deleteUser', scope.row.id);
              }
            "
            style="cursor: pointer;"
          >
            <i class="el-icon-delete warning-icon" />
          </span>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    }
  }
};
</script>
